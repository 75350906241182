import React from "react";
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { MDBCard, MDBCardBody, MDBCol } from "mdb-react-ui-kit";
import { Link } from "react-router-dom"; // Import Link from React Router
import { formatDateString } from "../../helpers/time";

const ActiveClientsTable = ({ activeClients }) => {
  return (
    <>
      <h1>Active Clients</h1>
      <MDBCol md="12">
        <MDBCard>
          <MDBCardBody>
            <Table aria-label="Active Clients">
              <TableHeader>
                <TableColumn>Company</TableColumn>
                <TableColumn>Website</TableColumn>
                <TableColumn>Next Payment Date</TableColumn>
                <TableColumn>Overdue Payment</TableColumn>
              </TableHeader>
              <TableBody>
                {activeClients && activeClients.length > 0 ? (
                  activeClients.map((client) => (
                    <TableRow key={client._id}>
                      <TableCell>
                        <Link to={`/all-users/${client._id}`} className="text-primary">
                          {client.company}
                        </Link>
                      </TableCell>
                      <TableCell>{client.website ? <a href={client.website} target="_blank" rel="noopener noreferrer">{client.website}</a> : "N/A"}</TableCell>
                      <TableCell>
                        {client.next_payment_date
                          ? formatDateString(client.next_payment_date)
                          : "N/A"}
                      </TableCell>
                      <TableCell>{client.has_overdue_payment ? "Yes" : "No"}</TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4}>No active clients found.</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </>
  );
};

export default ActiveClientsTable;

