import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { getReports } from "../../helpers/reportsApi";

import ErrorComponent from "../ErrorComponent";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import ActiveClientsTable from "./ActiveClients";
import OverduePayments from "./OverduePayments";
import StatsCard from "./StatsCard";
//import RenderLineChart from "../ClientComponents/RenderLineChart";
//import axios from "axios";
//import { Select, SelectItem } from "@nextui-org/react";

const AdminDashboard = () => {
  const [reportsData, setReportsData] = useState(null);
  const activeClients = reportsData?.activeClients || [];
  //const [analyticsData, setAnalyticsData] = useState(null);
  //const [filter, setFilter] = useState("7daysAgo");
  //const [activeTab, setActiveTab] = useState("pageVisits");

  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch Reports Data once on component mount
  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await getReports(user.token);
        console.log(response);
        setReportsData(response);
        setIsLoading(false);
      } catch (err) {
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchReports();
  }, [user.token]);

  /* 
  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        const query = `${process.env.REACT_APP_API_URL}/users/googleAnalytics?filterStartDate=${filter}`;

        const response = await axios.get(query, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });

        console.log("Analytics data fetched successfully", response.data);
        setAnalyticsData(response.data);
      } catch (err) {
        console.error("Error fetching analytics data:", err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchAnalyticsData();
  }, [filter, user.token]);

  function updateFilter(e) {
    setFilter(e.currentKey);
  }
*/
  if (isLoading) {
    return <LargeLoadingSpinner />;
  } else if (error) {
    return <ErrorComponent errorMessage={error} />;
  }

  return (
    <MDBContainer>
      {reportsData  && (
        <>
          <MDBRow>
            <MDBCol md="4">
              <StatsCard
                stat={reportsData.totalUsers}
                text="Total User Count"
              />
            </MDBCol>

            <MDBCol md="4">
              <StatsCard
                stat={`${reportsData.effectiveMrr}`}
                text="Effective MRR"
              />
            </MDBCol>

            <MDBCol md="4">
              <StatsCard
                stat={`${reportsData.totalCompletedAmount}`}
                text="Total Revenue for the month"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow className="mt-4">
            <MDBCol md="4">
              <StatsCard
                stat={reportsData.recurringPaymentsCount}
                text="Total Active Recurring payments"
              />
            </MDBCol>

            <MDBCol md="4">
              <StatsCard
                stat={`${reportsData.totalCompletedRecurringPaymentsAllTime}`}
                text="Total Recurring Revenue"
              />
            </MDBCol>

            <MDBCol md="4">
              <StatsCard
                stat={`${reportsData.totalCompletedPaymentsAllTime}`}
                text="Total Payments"
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <div className="mt-5 mx-auto">  {/*
                <div className="inline-flex items-center bg-[#3A71CA] rounded-full p-2 relative overflow-hidden">
                 
                  <span
                    className="rounded-full text-center px-2 py-1 mr-2 cursor-pointer bg-white text-[#3A71CA] relative z-10"
                    onClick={() => setActiveTab("pageVisits")}
                  >
                    Page Visits
                  </span>


                 <span
                    className={`rounded-full px-3 py-1 mr-2 cursor-pointer ${
                      activeTab === "pageVisits"
                        ? "text-blue-500"
                        : "text-white"
                    } relative z-10`}
                    onClick={() => setActiveTab("pageVisits")}
                  >
                    Page Visits
                  </span> */}
                  {/* <span
                    className={`rounded-full px-3 py-1 mr-2 cursor-pointer ${
                      activeTab === "income" ? "text-blue-500" : "text-white"
                    } relative z-10`}
                    onClick={() => setActiveTab("income")}
                  >
                    Income
                  </span>
                  <span
                    className={`rounded-full px-3 py-1 cursor-pointer ${
                      activeTab === "revenue" ? "text-blue-500" : "text-white"
                    } relative z-10`}
                    onClick={() => setActiveTab("revenue")}
                  >
                    Revenue
                  </span>
                  <span
                    className={`bg-white rounded-full absolute top-1 h-[calc(100%-8px)] transition-all duration-300 ease-in-out ${
                      activeTab === "pageVisits"
                        ? "left-3 w-[calc(33.33%-4px)]"
                        : activeTab === "income"
                        ? "left-[calc(36%+2px)] w-[calc(33.33%-4px)]"
                        : "left-[calc(66%+2px)] w-[calc(33.33%-4px)]"
                    }`}
                  ></span>
                  
                  
                  <h1>Google Analytics Data</h1>

                <div className="-ml-10">
                  <RenderLineChart analyticsData={analyticsData} />
                </div>
                
               
                </div>
                
                <Select
                  label="Select time range"
                  selectedKeys={[filter]}
                  variant="bordered"
                  className="max-w-xs"
                  onSelectionChange={updateFilter}
                >
                  <SelectItem key="7daysAgo" value="7daysAgo">
                    Last 7 days
                  </SelectItem>
                  <SelectItem key="today" value="today">
                    Today
                  </SelectItem>
                  <SelectItem key="30daysAgo" value="30daysAgo">
                    Last 30 days
                  </SelectItem>
                  <SelectItem key="90daysAgo" value="90daysAgo">
                    Quarter to date
                  </SelectItem>
                  <SelectItem key="365daysAgo" value="365daysAgo">
                    Last 12 months
                  </SelectItem>
                </Select>*/}
              </div>
            </MDBCol>
          </MDBRow> 
         
          <MDBRow className="mt-4">
            <ActiveClientsTable activeClients={activeClients} />
          </MDBRow>

          <MDBRow className="mt-4">
            <OverduePayments pendingPayments={reportsData.pendingPayments} />
          </MDBRow>
        </>
      )}
    </MDBContainer>
  );
};

export default AdminDashboard;
