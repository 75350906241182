import React, { useState, useEffect, useReducer, useContext } from "react";
import axios from "axios";
import BillingDetailsCard from "./BillingDetailsCard";
import { MDBCol, MDBBtn } from "mdb-react-ui-kit";
import {
  fetchBillingDetails,
  setSelectedBillingDetails,
} from "../../actions/checkoutActions";

import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import BillingDetailsForm from "./BillingDetailsForm";
import "./UpdateCard.css";
import { checkoutReducer, initialState } from "../../reducers/checkoutReducer";
import { UserContext } from "../../context/UserContext";

const UpdateCard = () => {
  const { user } = useContext(UserContext); // Access user from context
  const [state, dispatch] = useReducer(checkoutReducer, initialState);
  const [cardData, setCardData] = useState({
    cardNumber: "",
    month: "",
    year: "",
    cvv: "",
  });
  const [showBillingDetailsForm, setShowBillingDetailsForm] = useState(false);

  const { billingDetails, selectedBillingDetails } = state;

  useEffect(() => {
    if (user) {
      fetchBillingDetails(dispatch, user);
    }
  }, [dispatch, user]);

  const handleBillingDetailsSelection = (details) => {
    setSelectedBillingDetails(dispatch, details);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCardData({
      ...cardData,
      [name]: value,
    });
  };

  const handleUpdate = async () => {
    if (!selectedBillingDetails) {
      alert("Please select a billing address");
      return;
    }

    // Combine month and year into a single expiryDate string
    const expiryDate = `${cardData.year}${cardData.month.padStart(2, "0")}`;

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/checkout/update-card`, {
        userId: user._id,
        cardData: {
          cardNumber: cardData.cardNumber,
          expiryDate,
          cvv: cardData.cvv,
        },
        billingDetails: selectedBillingDetails,
      });
      alert("Payment method updated successfully");
    } catch (error) {
      console.error("Error updating payment method:", error);
      alert("Failed to update payment method");
    }
  };

  const hideForm = () => {
    setShowBillingDetailsForm(false);
  };

  return (
    <div className="update-card-container">
      <h3 className="update-card-heading">Update Payment Method</h3>

      <div className="update-card-form">
        {/* Card Number */}
        <div className="inputBox">
          <label htmlFor="cardNumber">Card Number</label>
          <input
            type="text"
            id="cardNumber"
            name="cardNumber"
            maxLength="16"
            value={cardData.cardNumber}
            onChange={handleInputChange}
            className="update-card-input"
            placeholder="Card Number"
          />
        </div>

     {/* Expiration Date */}
<div className="inputBox">
  <label htmlFor="expiration-date" className="expiration-heading">
    Expiration Date
  </label>
  <div className="flexbox expiration-row">
    {/* Expiration Month */}
    <div className="inputBox">
      <select
        id="month"
        name="month"
        value={cardData.month}
        onChange={handleInputChange}
        className="month-input"
      >
        <option value="" disabled>
          Month
        </option>
        {[...Array(12).keys()].map((i) => (
          <option key={i} value={`${i + 1}`.padStart(2, "0")}>
            {`${i + 1}`.padStart(2, "0")}
          </option>
        ))}
      </select>
    </div>

    {/* Expiration Year */}
    <div className="inputBox">
      <select
        id="year"
        name="year"
        value={cardData.year}
        onChange={handleInputChange}
        className="year-input"
      >
        <option value="" disabled>
          Year
        </option>
        {[...Array(10).keys()].map((i) => {
          const year = new Date().getFullYear() + i;
          return (
            <option key={year} value={year.toString().substr(-2)}>
              {year}
            </option>
          );
        })}
      </select>
    </div>
  </div>
</div>

        
        <div className="inputBox">
  
          <input
            type="text"
            id="cvv"
            name="cvv"
            maxLength="4"
            value={cardData.cvv}
            onChange={handleInputChange}
            className="update-card-input"
            placeholder="CVV"
          />
        </div>
      </div>

      {/* Billing Details Section */}
      <div className="billing-details-section">
        <MDBCol lg="8" mb="4">
          {billingDetails.isLoading ? (
            <LargeLoadingSpinner />
          ) : showBillingDetailsForm ? (
            <BillingDetailsForm
              user={user}
              hideForm={hideForm}
              dispatch={dispatch}
            />
          ) : billingDetails.data && billingDetails.data.length === 0 ? (
            <div>
              <h1>No Billing Details</h1>
              <MDBBtn onClick={() => setShowBillingDetailsForm(true)}>
                Add Billing Details
              </MDBBtn>
            </div>
          ) : (
            <div>
              <h2 className="mb-4">Please Select A Billing Address</h2>
              {billingDetails.data.map((details) => (
                <div
                  key={details._id}
                  onClick={() => handleBillingDetailsSelection(details)}
                >
                  <BillingDetailsCard
                    billingDetails={details}
                    billingDetailsId={selectedBillingDetails?._id}
                  />
                </div>
              ))}
              <MDBBtn onClick={() => setShowBillingDetailsForm(true)}>
                Add New
              </MDBBtn>
            </div>
          )}
        </MDBCol>
      </div>

      {/* Submit Button */}
      <button
        className="update-card-button"
        onClick={handleUpdate}
        disabled={!selectedBillingDetails}
      >
        Update Payment Method
      </button>
    </div>
  );
};

export default UpdateCard;
