import React, { useContext, useEffect, useState } from "react";
import AllPayments from "./AllPayments";
import { filterPayments } from "../../helpers/filterPayments";
import { adminGetAllPayments } from "../../helpers/paymentApi";
import { UserContext } from "../../context/UserContext";
import axios from "axios";

const AllPaymentsPage = () => {
  const [payments, setPayments] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [filteredQueryString, setFilteredQueryString] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [titles] = useState(["Maintenance Package", "Innovation Hub", "Website Services", "Mobile Application Instalment", "Web Application Instalment"]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchPayments = async () => {
      const paymentsObj = await adminGetAllPayments(
        user.token,
        pageNumber,
        filteredQueryString
      );
      const paymentsList = paymentsObj.data;
      setTotalPages(paymentsObj.totalPages);
      setPayments(paymentsList);
    };

    const fetchCompanies = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/companies`, {
          headers: {
            Authorization: `Bearer ${user.token}`
          }
        });
        setCompanies(response.data.data);
      } catch (err) {
        // console.error(err.message);
      }
    };


    fetchCompanies()
    fetchPayments();

  }, [user.token, pageNumber, filteredQueryString]);

  const handleFilterChange = (newFilters) => {
    console.log(newFilters)
    const filteredString = filterPayments(newFilters);

    setFilteredQueryString(filteredString);
  };

  return (
    <AllPayments
      payments={payments}
      companies={companies}
      handleFilterChange={handleFilterChange}
      titles={titles}
      totalPages={totalPages}
      pageNumber={pageNumber}
      setPageNumber={setPageNumber}
    />
  );
};

export default AllPaymentsPage;
