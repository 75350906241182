import React, { useState } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBCardText,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
} from "mdb-react-ui-kit";
import { format } from "date-fns"; 

const UserNotesCard = ({ userNotes = [], onAddNote, onEditNote, onDeleteNote }) => {
  const [noteData, setNoteData] = useState({ id: null, content: "" });
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleInputChange = (e) => {
    const { value } = e.target;
    setNoteData({ ...noteData, content: value });
  };

  const formatDateTime = (dateString) => {
    if (!dateString) return "N/A"; // If no date is available
    return format(new Date(dateString), "PPP pp"); // e.g., Jan 1, 2025 10:00 PM
  };

  const handleNoteSubmit = () => {
    if (noteData.id) {
      onEditNote({ id: noteData.id, content: noteData.content });
    } else {
      onAddNote(noteData.content);
    }
    setNoteData({ id: null, content: "" });
  };

  const handleEditClick = (note) => {
    setNoteData({ id: note._id, content: note.content });
  };

  const handleDeleteClick = (noteId) => {
    setNoteData({ id: noteId, content: "" });
    toggleModal();
  };

  const confirmDelete = () => {
    onDeleteNote(noteData.id);
    toggleModal();
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <MDBCardTitle>Account Notes</MDBCardTitle>
        {userNotes?.length === 0 ? (
          <p className="mt-4 mb-4">No notes available.</p>
        ) : (
          userNotes.map((note) => (
            <div
              key={note._id}
              className="d-flex flex-column mt-3 mb-2"
            >
              <div className="d-flex justify-content-between align-items-center">
              <div>
                <MDBCardText style={{ fontSize: "1rem" }} className="mb-1">
                  {note.content}
                </MDBCardText>
                <small style={{ fontSize: "0.8rem", color: "#6c757d" }} className="text-muted">
                  Last updated: {formatDateTime(note.updated_at || note.created_at)}
                </small>
              </div>
                <MDBDropdown>
                  <MDBDropdownToggle tag="button" className="btn btn-link btn-sm p-0">
                    <i className="fas fa-ellipsis-v"></i>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link onClick={() => handleEditClick(note)}>
                      Edit
                    </MDBDropdownItem>
                    <MDBDropdownItem link onClick={() => handleDeleteClick(note._id)}>
                      Delete
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </div>
              {/* Render Edit Form Below the Note Being Edited */}
              {noteData.id === note._id && (
                <div className="mt-2">
                  <MDBInput
                    label="Edit Note"
                    type="textarea"
                    value={noteData.content}
                    onChange={handleInputChange}
                    className="mb-2"
                  />
                  <MDBBtn size="sm" onClick={handleNoteSubmit}>
                    Save
                  </MDBBtn>
                  <MDBBtn
                    size="sm"
                    color="danger"
                    onClick={() => setNoteData({ id: null, content: "" })}
                  >
                    Cancel
                  </MDBBtn>
                </div>
              )}
            </div>
          ))
        )}
        {/* Add New Note Form */}
        {noteData.id === null && (
          <div className="mt-3">
            <MDBInput
              label="Add Note"
              type="textarea"
              value={noteData.content}
              onChange={handleInputChange}
              className="mb-2"
            />
            <MDBBtn size="sm" onClick={handleNoteSubmit}>
              Save
            </MDBBtn>
            <MDBBtn
              size="sm"
              color="danger"
              onClick={() => setNoteData({ id: null, content: "" })}
            >
              Cancel
            </MDBBtn>
          </div>
        )}
        
      </MDBCardBody>

      {/* Confirmation Modal */}
      <MDBModal open={modalOpen} setOpen={setModalOpen}>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>Confirm Delete</MDBModalHeader>
            <MDBModalBody>Are you sure you want to delete this note?</MDBModalBody>
            <MDBModalFooter>
              <MDBBtn onClick={toggleModal}>Cancel</MDBBtn>
              <MDBBtn color="danger" onClick={confirmDelete}>
                Delete
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBCard>
  );
};

export default UserNotesCard;

