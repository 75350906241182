import React, { useState } from "react";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";

const NewPaymentForm = ({ onSubmit, onCancel }) => {
  const [newPayment, setNewPayment] = useState({
    amount: "",
    title: "",
    description: "",
    due_date: "",
    isRecurring: false,
    last_payment_due_date: "",
    date_paid: null,
    currency: "US",
    frequency: "",
  });

  const tiers = {
    Basic: { price: 45, currency: "US" },
    Standard: { price: 130, currency: "US" },
    Advanced: { price: 225, currency: "US" },
    Premium: { price: 320, currency: "US" },
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;

    // Auto-populate amount and currency based on selected title or tier
    if (name === "title") {
      if (value === "Maintenance Package") {
        setNewPayment({
          ...newPayment,
          title: value,
          description: "",
          amount: "",
          currency: "US",
        });
      } else if (value === "Web Hosting Only") {
        setNewPayment({
          ...newPayment,
          title: value,
          description: "",
          amount: 11,
          currency: "US",
        });
      } else {
        setNewPayment({
          ...newPayment,
          title: value,
          description: "",
          amount: "",
        });
      }
    } else if (name === "description" && newPayment.title === "Maintenance Package") {
      const selectedTier = tiers[value];
      setNewPayment({
        ...newPayment,
        description: value,
        amount: selectedTier?.price || "",
        currency: selectedTier?.currency || "US",
      });
    } else {
      setNewPayment({
        ...newPayment,
        [name]: type === "checkbox" ? checked : value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formattedPayment = {
      ...newPayment,
      amount: newPayment.amount.toString(), 
    };
  
    onSubmit(formattedPayment);
  };
  
  return (
    <div>
      <h1 className="text-center my-3">Create New Invoice</h1>
      <form onSubmit={handleSubmit}>
        <select
          className="form-select my-3"
          name="title"
          value={newPayment.title}
          onChange={handleChange}
          required
        >
          <option value="">Select Service</option>
          <option value="Maintenance Package">Maintenance Package</option>
          <option value="Web Hosting Only">Web Hosting Only</option>
          <option value="Innovation Hub">Innovation Hub</option>
          <option value="Website Service">Website Service</option>
          <option value="Mobile Application Installment">
            Mobile Application Installment
          </option>
          <option value="Web Application Instalment">
            Web Application Instalment
          </option>
          <option value="Other service">Other service</option>
        </select>

        {newPayment.title === "Maintenance Package" ? (
          <select
            className="form-select my-3"
            name="description"
            value={newPayment.description}
            onChange={handleChange}
            required
          >
            <option value="">Select Tier</option>
            <option value="Basic">Basic: 45 USD</option>
            <option value="Standard">Standard: 130 USD</option>
            <option value="Advanced">Advanced: 225 USD</option>
            <option value="Premium">Premium: 320 USD</option>
          </select>
        ) : (
          <MDBInput
            label="Description"
            type="text"
            name="description"
            value={newPayment.description}
            onChange={handleChange}
            required
          />
        )}

        <MDBInput
          label="Due Date"
          type="date"
          name="due_date"
          value={newPayment.due_date}
          onChange={handleChange}
          required
        />
        <MDBInput
          label="Amount"
          type="number"
          name="amount"
          value={newPayment.amount}
          onChange={handleChange}
          required
          step="0.01"
          readOnly={newPayment.title === "Maintenance Package" || newPayment.title === "Web Hosting Only"}
        />

        <select
          className="form-select my-3"
          name="currency"
          value={newPayment.currency}
          onChange={handleChange}
          disabled={newPayment.title === "Maintenance Package" || newPayment.title === "Web Hosting Only"}
        >
          <option value="US">US Dollar</option>
          <option value="XCD">Eastern Caribbean Dollar (XCD)</option>
        </select>

        <div className="form-check my-3">
          <input
            className="form-check-input"
            type="checkbox"
            name="isRecurring"
            id="isRecurring"
            checked={newPayment.isRecurring}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="isRecurring">
            Is Recurring
          </label>
        </div>

        {newPayment.isRecurring && (
          <MDBInput
            label="Last Payment Due Date"
            type="date"
            name="last_payment_due_date"
            value={newPayment.last_payment_due_date}
            onChange={handleChange}
            required={newPayment.isRecurring}
          />
        )}

        {newPayment.isRecurring && (
          <select
            className="form-select my-3"
            name="frequency"
            value={newPayment.frequency}
            onChange={handleChange}
          >
            <option value="">Please Select Value</option>
            <option value="1y">Every Year</option>
            <option value="3m">Every 3 Months</option>
            <option value="1m">Every Month</option>
            <option value="1w">Every Week</option>
            <option value="1d">Every Day</option>
          </select>
        )}

        <MDBBtn type="submit">Submit</MDBBtn>
        <MDBBtn color="danger" onClick={onCancel}>
          Cancel
        </MDBBtn>
      </form>
    </div>
  );
};

export default NewPaymentForm;
