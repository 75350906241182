const initialState = {
  userDetails: {
    data: {},
    isLoading: true,
    error: null,
  },
  payments: {
    data: {
      totalPages: 0,
      data: [],
    },
    isLoading: true,
    error: null,
  },
  notes: {
    data: [], 
    isLoading: true,
    error: null,
  },
};

function userDetailsReducer(state, action) {
  switch (action.type) {
    case "FETCH_USER_START":
      return {
        ...state,
        userDetails: { ...state.userDetails, isLoading: true },
      };
    case "FETCH_USER_SUCCESS":
      return {
        ...state,
        userDetails: { isLoading: false, data: action.payload, error: null },
      };
    case "FETCH_USER_ERROR":
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          error: action.payload,
        },
      };

    // Payments Actions
    case "FETCH_PAYMENTS_START":
      return { ...state, payments: { ...state.payments, isLoading: true } };
    case "FETCH_PAYMENTS_SUCCESS":
      return {
        ...state,
        payments: { isLoading: false, data: action.payload, error: null },
      };
    case "FETCH_PAYMENTS_ERROR":
      return {
        ...state,
        payments: {
          ...state.payments,
          isLoading: false,
          error: action.payload,
        },
      };
    case "ADD_USER_PAYMENT":
      return {
        ...state,
        payments: {
          ...state.payments,
          data: {
            ...state.payments.data,
            data: [...state.payments.data.data, action.payload],
          },
        },
      };

    // User Notes Actions
    case "FETCH_NOTES_START":
      return { ...state, notes: { ...state.notes, isLoading: true } };
    case "FETCH_NOTES_SUCCESS":
      return {
        ...state,
        notes: { isLoading: false, data: action.payload, error: null },
      };
    case "FETCH_NOTES_ERROR":
      return {
        ...state,
        notes: { isLoading: false, data: [], error: action.payload },
      };
    case "ADD_NOTE_START":
      return { ...state, notes: { ...state.notes, isLoading: true } };
      case "ADD_NOTE_SUCCESS":
        return {
          ...state,
          notes: {
            ...state.notes,
            data: state.notes.data ? [...state.notes.data, action.payload] : [action.payload],
          },
        };      
    case "ADD_NOTE_ERROR":
      return {
        ...state,
        notes: { ...state.notes, isLoading: false, error: action.payload },
      };
    case "EDIT_NOTE_START":
      return { ...state, notes: { ...state.notes, isLoading: true } };
    case "EDIT_NOTE_SUCCESS":
      return {
        ...state,
        notes: {
          isLoading: false,
          data: state.notes.data.map((note) =>
            note.id === action.payload.id ? action.payload : note
          ),
          error: null,
        },
      };
    case "EDIT_NOTE_ERROR":
      return {
        ...state,
        notes: { ...state.notes, isLoading: false, error: action.payload },
      };
    case "DELETE_NOTE_START":
      return { ...state, notes: { ...state.notes, isLoading: true } };
      case "DELETE_NOTE_SUCCESS":
        return {
          ...state,
          notes: {
            ...state.notes,
            data: state.notes.data.filter((note) => note.id !== action.payload), 
          },
        };
      
    case "DELETE_NOTE_ERROR":
      return {
        ...state,
        notes: { ...state.notes, isLoading: false, error: action.payload },
      };

    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: { ...state.userDetails, data: action.payload },
      };

    default:
      return state;
  }
}

export { initialState, userDetailsReducer };

