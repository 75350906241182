import { 
  getOneUser, 
  fetchUserNotesApi, 
  createUserNoteApi, 
  updateUserNoteApi, 
  deleteUserNoteApi 
} from "../helpers/usersApi";
import { adminFindUserPayments } from "../helpers/paymentApi";


export const fetchUserDetails = async (dispatch, user, userId) => {
  dispatch({ type: "FETCH_USER_START" });
  try {
    const response = await getOneUser(user, userId);
    dispatch({ type: "FETCH_USER_SUCCESS", payload: response });
  } catch (error) {
    dispatch({ type: "FETCH_USER_ERROR", payload: error.message });
  }
};

export const fetchUserPayments = async (
  dispatch,
  userId,
  token,
  pageNumber
) => {
  dispatch({ type: "FETCH_PAYMENTS_START" });
  try {
    const response = await adminFindUserPayments(userId, token, pageNumber);
    dispatch({ type: "FETCH_PAYMENTS_SUCCESS", payload: response });
  } catch (error) {
    // console.error(error.message);
    dispatch({ type: "FETCH_PAYMENTS_ERROR", payload: error.message });
  }
};

export const addUserPayment = (dispatch, payment) => {
  dispatch({ type: "ADD_USER_PAYMENT", payload: payment });
};

export const setUserDetails = (dispatch, userDetails) => {
  dispatch({ type: "SET_USER_DETAILS", payload: userDetails });
};


export const fetchUserNotes = async (dispatch, userId, token) => {
  dispatch({ type: "FETCH_NOTES_START" });
  try {
    const response = await fetchUserNotesApi(userId, token);
    //const data = await response.json();
    dispatch({ type: "FETCH_NOTES_SUCCESS", payload: response });
  } catch (error) {
    console.error("Error fetching notes:", error.message);
    dispatch({ type: "FETCH_NOTES_ERROR", payload: error.message });
  }
};


export const addUserNote = async (dispatch, userId, token, noteContent) => {
  dispatch({ type: "ADD_NOTE_START" });
  try {
    const response = await createUserNoteApi(userId, token, noteContent);
    dispatch({ type: "ADD_NOTE_SUCCESS", payload: response });
    return response;
  } catch (error) {
    dispatch({ type: "ADD_NOTE_ERROR", payload: error.message });
    return { error: error.message };
  }
};

// Edit a user note
export const editUserNote = async (dispatch, userId, token, note) => {
  dispatch({ type: "EDIT_NOTE_START" });
  try {
    const response = await updateUserNoteApi(userId, token, note);
    dispatch({ type: "EDIT_NOTE_SUCCESS", payload: response });
    return response;
  } catch (error) {
    dispatch({ type: "EDIT_NOTE_ERROR", payload: error.message });
    return { error: error.message };
  }
};

export const deleteUserNote = async (dispatch, userId, token, noteId) => {
  console.log(noteId);
  dispatch({ type: "DELETE_NOTE_START" });
  try {
    const response = await deleteUserNoteApi(userId, token, noteId);
    dispatch({ type: "DELETE_NOTE_SUCCESS", payload: noteId });
    return response; 
  } catch (error) {
    dispatch({ type: "DELETE_NOTE_ERROR", payload: error.message });
    return { error: error.message };
  }
};
