import React, { useContext, useState, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdbreact";
import { UserContext } from "../../context/UserContext";
import { getAllPayments } from "../../helpers/usersApi";
import "./Client.css";
import UserInfo from "./UserInfo";
import TransactionHistorySingle from "./TransactionHistorySingle";
import LatestInvoice from "./LatestInvoice";
import { Link } from "react-router-dom";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import ErrorComponent from "../ErrorComponent";
import { findAllPendingPayments } from "../../helpers/paymentApi";
//import RenderLineChart from "./RenderLineChart";
//import axios from "axios";
const Client = () => {
  const { user } = useContext(UserContext);
  //const [analyticsData, setAnalyticsData] = useState(null);
  const [payments, setPayments] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  //const [activeTab, setActiveTab] = useState("pageVisits");

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setIsLoading(true);
        const fetchedPayments = await getAllPayments(user._id, user.token);
        const pendingPaymentsObj = await findAllPendingPayments(user, 1);
        if (
          fetchedPayments.message ===
          `Payments for ${user.first_name + " " + user.last_name}`
        ) {
          setPayments(fetchedPayments.data);
          setPendingPayments(pendingPaymentsObj.data);
        } else {
          setError(fetchedPayments.message);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };
/*
    async function fetchAnalyticsData() {
      try {
        console.log(user.token, "is tokenFetching Google Analytics data...");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/users/googleAnalytics`,
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        console.log("Analytics data fetched successfully");
        setAnalyticsData(response.data);
      } catch (error) {
        console.error("Error fetching analytics data:", error);
      }
    }
*/

    //fetchAnalyticsData();
    fetchPayments(); // eslint-disable-next-line
  }, [user._id, user.token]);

  isLoading && <LargeLoadingSpinner />;
  error && <ErrorComponent errorMessage={error} />;

  const today = new Date();

  const filteredPayments = pendingPayments.filter(
    (payment) => new Date(payment.due_date) < today
  );

  let balance = 0;
  let lastPayment;

  if (filteredPayments.length > 0) {
    balance = filteredPayments.reduce(
      (total, payment) => total + payment.amount,
      0
    );
    lastPayment = filteredPayments.reduce((earliest, current) =>
      new Date(current.due_date) < new Date(earliest.due_date)
        ? current
        : earliest
    );
  }
  /*const data = [
    { date: "1 jul", visits: 400 },
    { date: "2 jul", visits: 300 },
    { date: "4 jul", visits: 200 },
    { date: "5 jul", visits: 278 },
    { date: "6 jul", visits: 189 },
    { date: "7 jul", visits: 239 },
  ];*/


  return (
    <MDBContainer className="page-content">
      <MDBRow>
        <MDBCol md="6">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBCardTitle className="font-semi-bold title">
                Welcome {user.first_name},
              </MDBCardTitle>
              <MDBCardText className="font-semibold py-4 px-2 fs-2">
                See your latest invoice below
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="6">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBCardTitle className="font-semi-bold title">
                Amount Due
              </MDBCardTitle>
              <MDBCardText className="font-semibold py-4 px-2 fs-2">
                ${balance}
              </MDBCardText>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>
      <MDBRow>
        <MDBCol md="4">
          {lastPayment ? (
            <LatestInvoice payment={lastPayment} />
          ) : (
            <MDBCard className="card-height">
              <MDBCardBody>
                <MDBCardTitle className="font-semi-bold title">
                  No Invoice Available
                </MDBCardTitle>
              </MDBCardBody>
            </MDBCard>
          )}
        </MDBCol>
        <MDBCol md="4">
          <MDBCard className="card-height">
            <MDBCardBody>
              <MDBCardTitle className="font-semi-bold title">
                Transaction History
              </MDBCardTitle>
              {payments.length > 0 ? (
                <>
                  <div className="body-text">
                    {payments
                      .filter((payment) => payment.status !== "pending") // Filter for completed payments
                      .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
                      .slice(0, 4) // Take the top 4 payments
                      .map((payment) => {
                        return (
                          <TransactionHistorySingle
                            payment={payment}
                            key={payment._id}
                          />
                        );
                      })}
                  </div>
                  <div className="text-center see-more-box">
                    <Link className="see-more" to="/invoices">
                      See More
                    </Link>
                  </div>
                </>
              ) : (
                <MDBCardText>No Recent Transactions</MDBCardText>
              )}

              {/* ... */}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol md="4">
          <UserInfo user={user} />
        </MDBCol>
      </MDBRow>
{/*
      <MDBRow>
        <MDBCol>
          <div className="mt-5">
            <div className="inline-flex items-center bg-blue-500 rounded-full p-2 relative overflow-hidden">
              <span
                className={`rounded-full px-3 py-1 mr-2 cursor-pointer ${
                  activeTab === "pageVisits" ? "text-blue-500" : "text-white"
                } relative z-10`}
                onClick={() => setActiveTab("pageVisits")}
              >
                Page Visits
              </span>
              <span
                className={`rounded-full px-3 py-1 mr-2 cursor-pointer ${
                  activeTab === "income" ? "text-blue-500" : "text-white"
                } relative z-10`}
                onClick={() => setActiveTab("income")}
              >
                Income
              </span>
              <span
                className={`rounded-full px-3 py-1 cursor-pointer ${
                  activeTab === "revenue" ? "text-blue-500" : "text-white"
                } relative z-10`}
                onClick={() => setActiveTab("revenue")}
              >
                Revenue
              </span>
              <span
                className={`bg-white rounded-full absolute top-1 h-[calc(100%-8px)] transition-all duration-300 ease-in-out ${
                  activeTab === "pageVisits"
                    ? "left-3 w-[calc(33.33%-4px)]"
                    : activeTab === "income"
                    ? "left-[calc(36%+2px)] w-[calc(33.33%-4px)]"
                    : "left-[calc(66%+2px)] w-[calc(33.33%-4px)]"
                }`}
              ></span>
            </div>

           {data.length > 0 ? (
              <RenderLineChart data={data} />
            ) : (
              <p>Loading...</p>
            )}

            <RenderLineChart data={data} />

            <div>
              <div className="mt-3 w-fit d-flex flex-row gap-3 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: "20px",
                      height: "2px",
                      backgroundColor: "#3A71CA",
                    }}
                  ></div>
                  <span className="ms-2">Last 7 days</span>
                </div>

                <div className="d-flex align-items-center">
                  <div
                    style={{
                      width: "20px",
                      height: "2px",
                      borderTop: "2px dashed #3A71CA",
                    }}
                  ></div>
                  <span className="ms-2">Preceding period</span>
                </div>
              </div>
            </div>
          </div>
        </MDBCol>
      </MDBRow>
 <div>
        <h1>Google Analytics Data</h1>
        <pre>{JSON.stringify(analyticsData, null, 2)}</pre>
      </div>*/}
      
    </MDBContainer>
  );
};

export default Client;
