import React, { useContext, useEffect, useReducer, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { useParams } from "react-router-dom";
import UserDetailCard from "./UserDetailCard";
import UserPaymentsTable from "./UserPaymentsTable";
import NewPaymentForm from "./NewPaymentForm";
import UserNotesCard from "./UserNotesCard"; // Import UserNotesCard
import { createPayment } from "../../helpers/paymentApi";
import { errorToast, successfulToast } from "../../helpers/toasties";
import {
  initialState,
  userDetailsReducer,
} from "../../reducers/userDetailsReducer";
import {
  addUserPayment,
  fetchUserDetails,
  fetchUserPayments,
  setUserDetails,
  fetchUserNotes, // Fetch user notes
  addUserNote,
  editUserNote,
  deleteUserNote,
} from "../../actions/userDetailsActions";
import ErrorComponent from "../ErrorComponent";
import SmallLoadingSpinner from "../LoadingSpinners/SmallLoadingSpinner";
import LargeLoadingSpinner from "../LoadingSpinners/LargeLoadingSpinner";
import { MDBBtn } from "mdb-react-ui-kit";

const UserDetailsPage = () => {
  const { user } = useContext(UserContext);
  const { id } = useParams();
  const [state, dispatch] = useReducer(userDetailsReducer, initialState);
  const [showNewPaymentForm, setShowNewPaymentForm] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchUserDetails(dispatch, user, id);
        await fetchUserPayments(dispatch, id, user.token, pageNumber);
        await fetchUserNotes(dispatch, id, user.token); // Fetch user notes
      } catch (error) {
        console.error("Error fetching data:", error);
        errorToast("Failed to load user data. Please try again later.");
      }
    };

    fetchData();
  }, [id, user.token, user, pageNumber]);

  const { userDetails, payments, notes } = state;

  const handleCreatePayment = () => {
    setShowNewPaymentForm(true);
  };

  const pagesArray = Array.from(Array(payments.data.totalPages || 0).keys());

  const handleCancelNewPayment = () => {
    setShowNewPaymentForm(false);
  };

  const handleSubmitNewPayment = async (paymentData) => {
    const paymentObj = await createPayment(paymentData, id, user.token);
    if (
      paymentObj.message ===
      `Payment created for ${
        userDetails.data.first_name + " " + userDetails.data.last_name
      }`
    ) {
      addUserPayment(dispatch, paymentObj.data);
      setShowNewPaymentForm(false);
      successfulToast(paymentObj.message);
    } else {
      errorToast("Something went wrong");
    }
  };

  const handleUpdateUser = (updatedUser) => {
    setUserDetails(dispatch, updatedUser);
  };

  const handleAddNote = async (noteContent) => {
    try {
      const newNote = await addUserNote(dispatch, id, user.token, noteContent);
      await fetchUserNotes(dispatch, id, user.token);
      if (newNote.error) {
        errorToast("Failed to add note");
      } else {
        successfulToast("Note added successfully");
      }
    } catch (error) {
      console.error("Error adding note:", error);
      errorToast("Unexpected error occurred while adding note");
    }
  };
  

  const handleEditNote = async (note) => {
    try {
      const updatedNote = await editUserNote(dispatch, id, user.token, note);
      await fetchUserNotes(dispatch, id, user.token);
      if (updatedNote.error) {
        errorToast("Failed to edit note");
      } else {
        successfulToast("Note updated successfully");
      }
    } catch (error) {
      console.error("Error in handleEditNote:", error);
      errorToast("Unexpected error occurred while editing the note");
    }
  };
  
  const handleDeleteNote = async (noteId) => {
    if (!noteId) {
      console.error("Note ID is undefined. Cannot delete.");
      errorToast("Invalid note ID.");
      return;
    }
  
    try {
      const result = await deleteUserNote(dispatch, id, user.token, noteId);
      await fetchUserNotes(dispatch, id, user.token);

      console.log("Delete response:", result);
  
      if (result.error) {
        errorToast(result.error);
      } else {
        successfulToast("Note deleted successfully");
      }
    } catch (error) {
      console.error("Error in handleDeleteNote:", error);
      errorToast("Unexpected error occurred while deleting the note");
    }
  };
  
  
  

  if (userDetails.error)
    return <ErrorComponent errorMessage={userDetails.error} />;
  if (payments.error) return <ErrorComponent errorMessage={payments.error} />;
  if (notes.error) return <ErrorComponent errorMessage={notes.error} />;

  return (
    <div>
      {userDetails.isLoading ? (
        <SmallLoadingSpinner />
      ) : (
        <>
          <h1>User Details</h1>
          <UserDetailCard
            userInfo={userDetails.data}
            setUserInfo={handleUpdateUser}
          />
        </>
      )}

      {/* User Notes Section */}
      <hr className="my-4" />
      {notes.isLoading ? (
        <SmallLoadingSpinner />
      ) : (
        <UserNotesCard
        userNotes={notes.data || []}
          onAddNote={handleAddNote}
          onEditNote={handleEditNote}
          onDeleteNote={handleDeleteNote}
        />
      )}

  

      <hr className="my-4" />
      {showNewPaymentForm ? (
        <NewPaymentForm
          onSubmit={handleSubmitNewPayment}
          onCancel={handleCancelNewPayment}
        />
      ) : (
        <>
          {payments.isLoading ? (
            <LargeLoadingSpinner />
          ) : (
            <UserPaymentsTable
              payments={payments.data.data || []} // Safeguard payments
              handleCreatePayment={handleCreatePayment}
            />
          )}
        </>
      )}

      <div className="pagin-btn-container">
        {pagesArray.map((p) => (
          <MDBBtn
            key={p}
            className={pageNumber === p + 1 ? "pagin-btn active" : "pagin-btn"}
            onClick={() => setPageNumber(p + 1)}
          >
            {p + 1}
          </MDBBtn>
        ))}
      </div>
    </div>
  );
};

export default UserDetailsPage;
