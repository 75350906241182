import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalTitle,
  MDBModalFooter,
  MDBModalBody,
  MDBModalHeader,
} from "mdb-react-ui-kit";
import UserEditForm from "./UserEditForm";
import { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { deleteUser, updateUser } from "../../helpers/usersApi";
import { errorToast, successfulToast } from "../../helpers/toasties";
import { useNavigate } from "react-router-dom";

const UserDetailCard = ({ userInfo, setUserInfo }) => {
  const [showEditForm, setShowEditForm] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [basicModal, setBasicModal] = useState(false);

  const toggleOpen = () => setBasicModal(!basicModal);

  const editUser = async (userData) => {
    const userObj = await updateUser(user.token, userInfo._id, userData);
    if (
      userObj.message === `User with id ${userInfo._id} updated successfully`
    ) {
      successfulToast(userObj.message);
      setShowEditForm(false);
      if (user.is_admin) {
        setUserInfo(userObj.data);
      } else {
        setUser({ ...userObj.data, token: user.token });
        localStorage.setItem(
          "orbtronics-user",
          JSON.stringify({ ...userObj.data, token: user.token })
        );
      }
    } else {
      errorToast("Something went wrong");
    }
  };

  const handleDelete = async () => {
    const message = await deleteUser(user.token, userInfo._id);
    if (message === `User with id ${userInfo._id} deleted successfully`) {
      successfulToast(message);
      navigate("/all-users");
      toggleOpen();
    } else {
      errorToast("Something went wrong");
    }
  };

  return (
    <MDBCard>
      {showEditForm ? (
        <MDBCardBody>
          <UserEditForm
            userData={userInfo}
            onSubmit={editUser}
            onCancel={() => setShowEditForm(false)}
          />
        </MDBCardBody>
      ) : (
        <MDBCardBody>
          <MDBCardTitle>
            {userInfo.first_name} {userInfo.last_name}
          </MDBCardTitle>
          <p>Email: {userInfo.email}</p>
          <p>Role: {userInfo.is_admin ? "Admin" : "User"}</p>
          <p>Company: {userInfo.company}</p>
          <p>Company Address: {userInfo.companyAddress}</p>
          <p>Website: <a href="{userInfo.website}" target="_blank" rel="noopener noreferrer">{userInfo.website}</a></p>
          <p>Account Manager: {userInfo.account_manager}</p>
          <p>User Status: {userInfo.user_status}</p>
          <MDBBtn color="warning" onClick={() => setShowEditForm(true)}>
            Edit
          </MDBBtn>
          {user.is_admin && (
            <MDBBtn color="danger" onClick={toggleOpen}>
              Delete
            </MDBBtn>
          )}
        </MDBCardBody>
      )}
      <MDBModal open={basicModal} setOpen={setBasicModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Delete User</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              Are You Sure You Want To Delete This User?
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn onClick={toggleOpen}>No</MDBBtn>
              <MDBBtn color="danger" onClick={handleDelete}>
                {" "}
                Yes
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBCard>
  );
};

export default UserDetailCard;
