import { Button, Input } from "@nextui-org/react";
import { MDBCard, MDBCardBody, MDBCol, MDBRow } from "mdb-react-ui-kit";
import React, { useState } from "react";
import { errorToast, successfulToast } from "../../helpers/toasties";
import { createOneUser } from "../../helpers/usersApi";

const UserForm = ({ handleAddUser, user }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    is_admin: false,
    company: "",
    companyAddress: "",
    phone: "",
    website:"",
    account_manager:"",
  });
  const [passwordError, setPasswordError] = useState("");
  const [isStrongPassword, setIsStrongPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      validatePassword(value);
    }
  };

  const validatePassword = (password) => {
    let errorMessage = "";
    if (!/[a-z]/.test(password)) {
      errorMessage += "Add at least one lowercase letter. ";
    }
    if (!/[A-Z]/.test(password)) {
      errorMessage += "Add at least one uppercase letter. ";
    }
    if (!/[0-9]/.test(password)) {
      errorMessage += "Include at least one number. ";
    }
    if (!/[!@#$%^&*]/.test(password)) {
      errorMessage += "Include at least one special character (!@#$%^&*). ";
    }
    if (password.length < 8) {
      errorMessage += "Password must be at least 8 characters long.";
    }

    setPasswordError(errorMessage);
    setIsStrongPassword(errorMessage === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (formData.password !== formData.confirm_password) {
      setPasswordError("Passwords do not match.");
      return;
    }
    if (!isStrongPassword) {
      setPasswordError("Password is not strong enough.");
      return;
    }

    const userData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      password: formData.password,
      is_admin: formData.is_admin,
      company: formData.company,
      companyAddress: formData.companyAddress,
      phone: formData.phone,
      website:formData.website,
      account_manager:formData.account_manager,
    };

    try {
      const userObj = await createOneUser(user, userData);
      // console.log(userObj);
      if (userObj.message === "A User was created!") {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          confirm_password: "",
          is_admin: false,
          company: "",
          companyAddress: "",
          phone: "",
          website:"",
          account_manager:"",
        });
        successfulToast(userObj.message);
        handleAddUser(userObj.data);
      } else {
        errorToast(userObj.message || "Something went wrong");
      }
    } catch (error) {
      // console.error(error.message);
      errorToast(error || "Something went wrong");

      return;
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <MDBCard>
      <MDBCardBody>
        <form onSubmit={handleSubmit}>
          <MDBRow>
            <MDBCol>
              <Input
                className="nextui-input my-2"
                label="First Name"
                name="first_name"
                onChange={handleInputChange}
                required
              />
            </MDBCol>
            <MDBCol>
              <Input
                className="nextui-input my-2"
                label="Last Name"
                name="last_name"
                onChange={handleInputChange}
                required
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <Input
                className="nextui-input my-2"
                label="Phone"
                type="tel"
                name="phone"
                onChange={handleInputChange}
                required
              />
            </MDBCol>
            <MDBCol>
              <Input
                className="nextui-input my-2"
                label="Email"
                type="email"
                name="email"
                onChange={handleInputChange}
                required
              />
            </MDBCol>
          </MDBRow>

          <MDBRow>
            <MDBCol>
              <Input
                className="nextui-input my-2"
                label="Password"
                type="password"
                name="password"
                onChange={handleInputChange}
                required
              />
            </MDBCol>
            <MDBCol>
              <Input
                className="nextui-input my-2"
                label="Confirm Password"
                type="password"
                name="confirm_password"
                onChange={handleInputChange}
                required
              />
            </MDBCol>
            <div>
              <p className="pw-instructions">
                Password must contain at least one lowercase letter, one
                uppercase letter, one number, one special character (!@#$%^&*),
                and be at least 8 characters long.
              </p>
              {passwordError && <p className="text-danger">{passwordError}</p>}
            </div>
          </MDBRow>

          <Input
            className="nextui-input my-3"
            label="Company"
            name="company"
            onChange={handleInputChange}
            required
          />
          <Input
            className="nextui-input my-3"
            label="Company Address"
            name="companyAddress"
            onChange={handleInputChange}
            required
          />
          <Input
            className="nextui-input my-3"
            label="Website"
            name="website"
            onChange={handleInputChange}
           
          />
          <Input
            className="nextui-input my-3"
            label="Account Manager"
            name="account_manager"
            onChange={handleInputChange}
          />
          <div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="isAdminCheck"
                name="is_admin"
                onChange={(e) =>
                  setFormData({ ...formData, is_admin: e.target.checked })
                }
              />
              <label className="form-check-label" htmlFor="isAdminCheck">
                Is Admin
              </label>
            </div>{" "}
          </div>
          {passwordError && <p className="text-danger">{passwordError}</p>}
          <Button
            color="primary"
            type="submit"
            className="mt-2"
            isLoading={isSubmitting}
          >
            Submit
          </Button>
        </form>
      </MDBCardBody>
    </MDBCard>
  );
};

export default UserForm;
